import React, {createRef, MouseEventHandler, useCallback, useEffect} from 'react';
import { useRouter } from 'next/router';
import ParagraphProps from '@src/components/Paragraph/paragraph-props';
import getCmp from '@src/utils/cmp';
import {trackEvent} from "@src/utils/et";
import {Models} from "@data/et-web-api";

const TextParagraph = (props: ParagraphProps) => {
  const ref = createRef<HTMLTableSectionElement>()
  const router = useRouter();

  const onClickText: MouseEventHandler<HTMLElement> = e => {
    const targetLink = (e.target as HTMLElement).closest('a');
    if (!targetLink) return;

    if (targetLink.className.split(' ').indexOf('cmp-show') !== -1) {
      e.preventDefault();
      getCmp().then(cmp => {
        cmp?.showBanner();
      });
      return;
    }

    const { href } = targetLink;
    const url = new URL(href);
    const urlTop = url.hostname.split('.').slice(-2).join('.');
    const locationTop = document.location.hostname.split('.').slice(-2).join('.');
    if (urlTop === locationTop) {
      e.preventDefault();
      router.push('/', url.pathname).catch(reason => {
        console.log('Could not push local pathname', reason, url);
        window.location.href = href;
      });
    }
  };

  const trackReadMoreLink = (linkEl: HTMLAnchorElement) => {
    const eventName: Models.TeaserClick['eventName'] = 'Teaser Click';
    const eventPayload: Models.TeaserClick['eventPayload'] = {
      targetScreenName : 'Article Detail',
      teaserName: `Mehr lesen: ${linkEl.textContent}`,
      targetUrl: linkEl.getAttribute('href') || undefined,
    }
    trackEvent(eventName, eventPayload);
  };

  const onClickReadMore = useCallback((e: UIEvent) => {
    const targetEl = e.target as HTMLElement
    if (e.isTrusted && targetEl.tagName === 'A' && !!targetEl.closest('.read-more-list')) {
      trackReadMoreLink(e.target as HTMLAnchorElement)
    }
  }, [])

  useEffect(() => {
    const container = ref.current
    container?.addEventListener('click', onClickReadMore, false)

    return () => container?.removeEventListener('click', onClickReadMore)
  }, [onClickReadMore, ref])

  return (
    <section ref={ref}
      className="paragraph text-paragraph"
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: props.paragraph.data.html }}
      onClick={onClickText}
    />
  );
};

export default TextParagraph;
